import { permissions } from "@/shared/permissions";
import {
  AppPermission,
  ModulePermission,
  Permission,
} from "@/auth/permissions";
import { removeDuplicates } from "@/shared/utils/set";

export function permissionKey(
  appName: string,
  moduleName?: string,
  permissionName?: string
) {
  if (!moduleName) {
    return `${appName}.*.*`;
  } else if (!permissionName) {
    return `${appName}.${moduleName}.*`;
  } else {
    return `${appName}.${moduleName}.${permissionName}`;
  }
}

export function listLinkedPermissionsFromKeys(keys: string[]): string[] {
  const result: string[] = [];
  for (const perm of keys) {
    result.push(...listLinkedPermissionsFromKey(perm));
  }
  return removeDuplicates(result);
}
export function listLinkedPermissionsFromKey(key: string): string[] {
  const result: string[] = [];
  const [appDef, moduleDef, permDef] = getPermissionFromKey(key);
  if (appDef && !moduleDef) result.push(...listPermissionsInApp(appDef, true));
  else if (appDef && moduleDef && !permDef)
    result.push(...listPermissionsInModule(appDef.name, moduleDef, true));
  else if (appDef && moduleDef && permDef)
    result.push(
      ...listLinkedPermissions(appDef.name, moduleDef.name, permDef, true)
    );
  if (appDef) {
    result.push(key);
  }
  return removeDuplicates(result);
}
export function listLinkedPermissions(
  app: string,
  module: string,
  permission: Permission,
  withDependent?: boolean
): string[] {
  const result: string[] = [permissionKey(app, module, permission.name)];
  if (withDependent && permission.dependOn) {
    for (const depKey of permission.dependOn) {
      result.push(...listLinkedPermissionsFromKey(depKey));
    }
  }
  return removeDuplicates(result);
}
export function listPermissionsInModule(
  app: string,
  modulePermission: ModulePermission,
  withDependent?: boolean
): string[] {
  const result: string[] = [];
  const module = modulePermission.name;
  for (const perm of modulePermission.permissions) {
    result.push(...listLinkedPermissions(app, module, perm, withDependent));
  }
  return removeDuplicates(result);
}

export function listPermissionsInApp(
  appPermission: AppPermission,
  withDependent?: boolean
): string[] {
  const app = appPermission.name;
  const result: string[] = [];
  for (const modulePerm of appPermission.modules) {
    result.push(...listPermissionsInModule(app, modulePerm, withDependent));
    result.push(permissionKey(appPermission.name, modulePerm.name));
  }
  return removeDuplicates(result);
}

export function listAllPermissions(): string[] {
  const result: string[] = [];
  for (const appPermission of permissions) {
    result.push(...listPermissionsInApp(appPermission));
    result.push(permissionKey(appPermission.name));
  }
  return removeDuplicates(result);
}

export function getModuleFromRouterName(
  routerName: string
): [AppPermission, ModulePermission] | null {
  for (const appPermission of permissions) {
    for (const modulePerm of appPermission.modules) {
      if (modulePerm.routerName === routerName) {
        return [appPermission, modulePerm];
      }
    }
  }
  return null;
}

export function getPermissionFromKey(
  key: string
): [AppPermission | null, ModulePermission | null, Permission | null] {
  for (const appPermission of permissions) {
    if (permissionKey(appPermission.name) === key)
      return [appPermission, null, null];
    for (const modulePermission of appPermission.modules) {
      if (permissionKey(appPermission.name, modulePermission.name) === key)
        return [appPermission, modulePermission, null];

      for (const permission of modulePermission.permissions) {
        if (
          permissionKey(
            appPermission.name,
            modulePermission.name,
            permission.name
          ) === key
        )
          return [appPermission, modulePermission, permission];
      }
    }
  }
  return [null, null, null];
}

export function cleanPermissionSet(permissionsKeys: string[]): string[] {
  const appPermissions = listAllPermissions();
  permissionsKeys = permissionsKeys.filter((p) => appPermissions.includes(p));
  return listLinkedPermissionsFromKeys(permissionsKeys);
}

export function hasAppDep(appName: string, permissionsKeys: string[]): boolean {
  return permissionsKeys.filter((p) => p.startsWith(`${appName}.`)).length > 0;
}
export function hasModuleDep(
  appName: string,
  moduleName: string,
  permissionsKeys: string[]
): boolean {
  return (
    permissionsKeys.filter((p) => p.startsWith(`${appName}.${moduleName}.`))
      .length > 0
  );
}

export function isADependency(key: string, perms: string[]): boolean {
  return listLinkedPermissionsFromKeys(perms.filter((p) => p !== key)).includes(
    key
  );
}
