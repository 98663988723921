import { RouteRecordRaw } from "vue-router";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "routing/countries",
    name: "telephony-routing-countries",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/CountriesView.vue"
      ),
  },
  {
    path: "routing/countries/:id",
    name: "telephony-routing-countries-detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/CountryDetail.vue"
      ),
  },
  {
    path: "routing/global-aggregates",
    name: "telephony-routing-global-aggregates",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/GlobalAggregatesView.vue"
      ),
  },
  {
    path: "routing/global-aggregates/:id",
    name: "telephony-routing-global-aggregates-detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/GlobalAggregateDetail.vue"
      ),
  },
  {
    path: "routing/business-aggregates",
    name: "telephony-routing-source-aggregates",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/SourceAggregatesView.vue"
      ),
  },
  {
    path: "routing/business-aggregates/:id",
    name: "telephony-routing-source-aggregates-detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/SourceAggregateDetail.vue"
      ),
  },
  {
    path: "routing/callback-sources",
    name: "telephony-routing-sources",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/SourcesView.vue"
      ),
  },
  {
    path: "routing/sources/:id",
    name: "telephony-routing-sources-detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/SourceDetail.vue"
      ),
  },
  {
    path: "routing/campaigns-equivalencies",
    name: "telephony-routing-campaigns-equivalencies",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/CampaignsEquivalenciesView.vue"
      ),
  },
  {
    path: "routing/campaigns-equivalencies/:id",
    name: "telephony-routing-campaigns-equivalencies-detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/CampaignEquivalencyDetail.vue"
      ),
  },
  {
    path: "routing/dc-campaigns-automations",
    name: "telephony-routing-dc-campaigns-automations",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/DcCampaignsAutomationView.vue"
      ),
  },
  {
    path: "routing/dc-campaigns-automations/:id",
    name: "telephony-routing-dc-campaigns-automations-detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/DcCampaignsAutomationDetail.vue"
      ),
  },
  {
    path: "routing/simulate/campaigns-equivalencies/matching",
    name: "telephony-routing-simulate-matching-campaigns-equivalencies",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/SimulateCEMatchingView.vue"
      ),
  },
  {
    path: "routing/simulate/callback-request",
    name: "telephony-routing-simulate-callback-request",
    props: { simulation: true },
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/ProcessCallbackRequestView.vue"
      ),
  },
  {
    path: "routing/process/callback-request",
    name: "telephony-routing-process-callback-request",
    props: { simulation: false },
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/ProcessCallbackRequestView.vue"
      ),
  },
  {
    path: "routing/callback-request-logs",
    name: "telephony-routing-callback-request-logs",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/CallbackRequestLogView.vue"
      ),
  },
  {
    path: "tools/diabolocom",
    name: "telephony-tools-diabolocom",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/DcTools.vue"
      ),
  },
  {
    path: "calls",
    name: "telephony-calls-history",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/calls/CallHistory.vue"
      ),
  },
  {
    path: "calls/:id",
    name: "telephony-calls-history-detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/calls/CallDetail.vue"
      ),
  },
  {
    path: "alert-reports",
    name: "telephony-alert-report",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/routing/AlertsView.vue"
      ),
  },
  {
    path: "config/inbound-services",
    name: "telephony-config-inbound-services",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/config/InboundServicesView.vue"
      ),
  },
  {
    path: "config/inbound-services/:id",
    name: "telephony-config-inbound-services-detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/config/InboundServiceConfigurationDetail.vue"
      ),
  },
  {
    path: "config/archi-telephony",
    name: "telephony-config-archi-telephony",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/config/ArchiTelephonyView.vue"
      ),
  },
  {
    path: "config/sms-forwarding",
    name: "telephony-config-sms-forwarding",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/config/SMSForwardingView.vue"
      ),
  },
  {
    path: "config/blacklist",
    name: "telephony-config-blacklist",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/config/BlacklistedPhoneView.vue"
      ),
  },
  {
    path: "config/dynamic-properties",
    name: "telephony-config-dynamic-properties",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/config/DynamicPropertyView.vue"
      ),
  },
  {
    path: "config/dynamic-properties/:id",
    name: "telephony-config-dynamic-properties-detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/config/DynamicPropertyDetail.vue"
      ),
  },
  {
    path: "timeslots/aggregates",
    name: "telephony-timeslots-aggregates",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/timeslots/TimeslotsAggregatesView.vue"
      ),
  },
  {
    path: "timeslots/aggregates/:aggregateId/timeslots",
    name: "telephony-timeslots-details",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/timeslots/TimeslotsView.vue"
      ),
  },
  {
    path: "timeslots/aggregates/:id",
    name: "telephony-timeslots-aggregates-detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/timeslots/TimeslotsAggregateDetail.vue"
      ),
  },
  {
    path: "planned-callbacks",
    name: "telephony-planned-callbacks",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "../telephony/views/callback/PlannedCallbackView.vue"
      ),
  },
  {
    path: "source-qualif",
    name: "telephony-routing-sources-qualif",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "./views/sourceQualif/SourcesQualifView.vue"
      ),
  },
  {
    path: "source-qualif/create",
    name: "telephony-routing-sources-qualif-create",
    props: { source: null },
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "./views/sourceQualif/SourcesQualifEditView.vue"
      ),
  },
  {
    path: "source-qualif/edit/:id",
    name: "telephony-routing-sources-qualif-edit",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "./views/sourceQualif/SourcesQualifEditView.vue"
      ),
  },
  {
    path: "outbound-campaign-qualification-rules",
    name: "telephony-routing-outbound-campaign-qualification-rules",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "./views/outboundCampaignQualificationRules/OutboundCampaignQualificationRulesView.vue"
      ),
  },
  {
    path: "outbound-campaign-qualification-rule/creates",
    name: "telephony-routing-outbound-campaign-qualification-rules-create",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "./views/outboundCampaignQualificationRules/OutboundCampaignQualificationRulesEditView.vue"
      ),
  },
  {
    path: "outbound-campaign-qualification-rules/edit/:id",
    name: "telephony-routing-outbound-campaign-qualification-rules-edit",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "./views/outboundCampaignQualificationRules/OutboundCampaignQualificationRulesEditView.vue"
      ),
  },
  {
    path: "regionalization-qualification-rules",
    name: "telephony-routing-regionalization-qualification-rules",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "./views/regionalizationQualificationRules/RegionalizationQualificationRulesView.vue"
      ),
  },
  {
    path: "regionalization-qualification-rule/creates",
    name: "telephony-routing-regionalization-qualification-rules-create",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "./views/regionalizationQualificationRules/RegionalizationQualificationRulesEditView.vue"
      ),
  },
  {
    path: "regionalization-qualification-rules/edit/:id",
    name: "telephony-routing-regionalization-qualification-rules-edit",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "./views/regionalizationQualificationRules/RegionalizationQualificationRulesEditView.vue"
      ),
  },
  {
    path: "routing/phone-numbers-pool",
    name: "telephony-routing-phone-numbers-pool",
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "./views/routing/PhoneNumbersPoolView.vue"
      ),
  },
  {
    path: "routing/phone-numbers-pool/:id",
    name: "telephony-routing-phone-numbers-pool-detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "telephony-routing" */ "./views/routing/PhoneNumbersPoolDetail.vue"
      ),
  },
];
