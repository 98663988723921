<template>
  <simple-badge :color="envColor || 'secondary'" marge-start>
    {{ envName }}
  </simple-badge>
</template>

<script setup>
import { isLocalhost } from "@/shared/utils/location";
import { backendEnvName } from "@/shared/utils/amplify";
import SimpleBadge from "@/shared/components/utils/SimpleBadge.vue";

const envNamePrefix = isLocalhost ? "Local/" : "";
const envName = envNamePrefix + backendEnvName;
const envColor = isLocalhost
  ? backendEnvName === "prod"
    ? "red"
    : "green"
  : backendEnvName === "prod"
  ? "blue"
  : "yellow";
</script>

<style scoped></style>
