import { API, Auth } from "aws-amplify";
type paginatedResponse<T, KeyName extends string> = {
  [key in KeyName]: T[];
} & { NextToken?: string };
async function getHeader() {
  return {
    "Content-Type": "application/json",
    Authorization: `${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`,
  };
}

export async function performAction(
  path: string,
  params: Record<string, string>
): Promise<void> {
  const payload: Record<string, unknown> = {
    body: params,
    headers: await getHeader(),
  };
  await API.post("AdminQueries", path, payload);
}

export async function paginatedGet<T, KeyName extends string>(
  path: string,
  keyName: KeyName,
  params: Record<string, string>
): Promise<T[]> {
  let nextToken: string | undefined = undefined;
  const result: T[] = [];
  do {
    const payload: Record<string, unknown> = {
      queryStringParameters: { limit: 60, token: nextToken, ...params },
      headers: await getHeader(),
    };

    const response: paginatedResponse<T, KeyName> = await API.get(
      "AdminQueries",
      path,
      payload
    );
    nextToken = response.NextToken;
    result.push(...response[keyName]);
  } while (nextToken);

  return result;
}

export interface rawAttribute {
  Name: string;
  Value: string;
}
export interface rawUser {
  Enabled: boolean;
  UserCreateDate: string;
  UserLastModifiedDate: string;
  UserStatus: string;
  Username: string;
  Attributes: rawAttribute[];
}
export interface rawUserGroup {
  GroupName: string;
  Description: string;
}
export interface rawGroup {
  CreationDate: string;
  Description: string;
  GroupName: string;
  LastModifiedDate: string;
  Precedence?: number;
  RoleArn?: string;
  UserPoolId: string;
}

export async function listGroups(): Promise<rawGroup[]> {
  return await paginatedGet("/listGroups", "Groups", {});
}
export async function listGroupsForUser(
  username: string
): Promise<rawUserGroup[]> {
  return await paginatedGet("/listGroupsForUser", "Groups", {
    username: username,
  });
}
export async function listUsers(): Promise<rawUser[]> {
  return await paginatedGet("/listUsers", "Users", {});
}
export async function listUsersInGroup(groupname: string): Promise<rawUser[]> {
  return await paginatedGet("/listUsersInGroup", "Users", {
    groupname: groupname,
  });
}
export async function disableUser(username: string): Promise<void> {
  await performAction("/disableUser", { username: username });
}
export async function enableUser(username: string): Promise<void> {
  await performAction("/enableUser", { username: username });
}
export async function removeUserFromGroup(
  username: string,
  groupname: string
): Promise<void> {
  await performAction("/removeUserFromGroup", {
    username: username,
    groupname: groupname,
  });
}

export async function addUserToGroup(
  username: string,
  groupname: string
): Promise<void> {
  await performAction("/addUserToGroup", {
    username: username,
    groupname: groupname,
  });
}

export async function createGroup(groupname: string): Promise<void> {
  await performAction("/createGroup", {
    groupname: groupname,
  });
}

export async function deleteGroup(groupname: string): Promise<void> {
  await performAction("/deleteGroup", {
    groupname: groupname,
  });
}
