import { API } from "aws-amplify";

export interface RawProfile {
  name: string;
  description: string;
  permissionIds: string[];
}

class RawProfileManager {
  async get(name: string): Promise<RawProfile> {
    return await API.get("db", `/profiles/object/${name}`, {});
  }
  async list(): Promise<RawProfile[]> {
    return await API.get("db", "/profiles", {});
  }
  async create(
    name: string,
    description: string,
    permissionIds: string[]
  ): Promise<void> {
    await API.post("db", "/profiles", {
      body: {
        name: name,
        description: description,
        permissionIds: permissionIds,
      },
    });
  }
  async update(
    name: string,
    description: string,
    permissionIds: string[]
  ): Promise<void> {
    await API.put("db", "/profiles", {
      body: {
        name: name,
        description: description,
        permissionIds: permissionIds,
      },
    });
  }
  async delete(name: string): Promise<void> {
    await API.del("db", `/profiles/object/${name}`, {});
  }
}
export const rawProfiles = new RawProfileManager();
