/** Each API route must have a specific method listed, that must match a possible http method */
export type Methods = "POST" | "GET" | "PATCH" | "PUT" | "DELETE";

/**
 * An `ApiRoute` define a specific API endpoint that must be allowed.
 */
export interface ApiRoute {
  // The api method to allow on this endpoint
  method: Methods;
  // Url endpoint must be defined by, either:
  // - a path (will check the endpoint by exact matching)
  path?: string;
  // - a (python-compatible) regular expression.
  re?: string;
  // If your URL is a constant path, please use the path parameter because it is a bit faster to resolve.
  // Even if not specified in the type, the logic imply that one of the two should be specified, but not both.
}

/**
 * A specific permission.
 */
export interface Permission {
  // Name of the permission. Must be unique across a module
  name: string;
  // A description, used to allow user admin know what it refers too.
  description: string;
  // A list of routes that this permission allow.
  routes?: ApiRoute[];
  // A dependant permission: allow list other permissions keys that must be allowed with that one.
  dependOn?: string[];
}

/**
 * A specific module permission.
 */
export interface ModulePermission {
  // Name of the module permission. Must be unique across an application.
  name: string;
  // A description, used to allow user admin know what it refers too.
  description: string;
  // An optional router name. If specified, navigation elements will automatically be shown/hidden based on that.
  // It also allow you to retrieve an user permission only with there name inside those views.
  routerName?: string;
  // A set of permissions.
  permissions: Permission[];
}

/**
 * A specific app permission
 */
export interface AppPermission {
  // Name of the module permission. Must be unique across paperoffice.
  name: string;
  // A description, used to allow user admin know what it refers too.
  description: string;
  // A set of modules permissions.
  modules: ModulePermission[];
}

// Helpers
export const UUID_RE =
  "[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}";
