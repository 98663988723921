/**
 * Sentry related utilities
 */
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { App } from "vue";
import { Router } from "vue-router";
import { backendEnvName } from "@/shared/utils/amplify";

/** Configure sentry on the provided vuejs app */
export function configureSentry(app: App<unknown>, router: Router) {
  Sentry.init({
    app,
    dsn: "https://5a1b4ffffdbc4b55a90c3f2fb944159d@sentry.papernest.com/16",
    release: process.env.RELEASE,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "localhost",
          "https://staging.d2bi6pr5g5u5zt.amplifyapp.com",
          "https://staging.paperoffice.ops.papernest.com",
          "https://prod.d2bi6pr5g5u5zt.amplifyapp.com",
          "https://paperoffice.ops.papernest.com",
          "https://3l3b5uunbi.execute-api.eu-west-3.amazonaws.com",
          "https://fu2bswch0j.execute-api.eu-west-3.amazonaws.com",
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
    trackComponents: true,
    environment: backendEnvName,
  });
}
