/**
 * Amplify related utilities
 */
import awsExports from "@/aws-exports";
import { cleanLocation } from "@/shared/utils/location";
import { Amplify } from "aws-amplify";


export const dev_mode = process.env.NODE_ENV === 'development';

// Add object that will contain local url
if (dev_mode) {
    awsExports.aws_cloud_logic_custom.push({
      "name": "telephony_proxy",
      "endpoint": process.env.VUE_APP_TELEPHONY_ENDPOINT || "",
      "region": "eu-west-3"
  })

  awsExports.aws_cloud_logic_custom.push({
    "name": "offer_catalog_proxy",
    "endpoint": process.env.VUE_APP_OFFER_ENDPOINT || "",
    "region": "eu-west-3"
  })

}

// Variables that contains urls / proxy name to be used
export const telephony_url_prefix = dev_mode ? "" : `/${process.env.VUE_APP_PROXY_PREFIX_TELEPHONY}`;
export const telephony_proxy = dev_mode ? `${process.env.VUE_APP_PROXY_PREFIX_TELEPHONY}_proxy` : "proxy";
export const telephony_updater_header = { headers : { updater : 'dev'  }};

export const offer_url_prefix = dev_mode ? "" : `/${process.env.VUE_APP_PROXY_PREFIX_OFFER}`;
export const offer_proxy = dev_mode ? `${process.env.VUE_APP_PROXY_PREFIX_OFFER}_proxy` : "proxy";

// There is no easy way to retrieve the env name from amplify data BUT the env name is in fact in lambda endpoint
// So we used it here in order to deduce current env
const _lambdaEndpoint = new URL(
  awsExports.aws_cloud_logic_custom
    .filter((e) => e.name === "proxy")
    .map((e) => e.endpoint)[0]
);
/** The name of the amplify backend environment we are currently connected too */
export const backendEnvName = dev_mode ? "localhost" : _lambdaEndpoint.pathname.substring(1);

/** Configure the amplify framework */
export function configureAmplify() {
  // Here we suppose the current location is correctly configured in amplify config
  const updatedAwsConfig = {
    ...awsExports,
    oauth: {
      ...awsExports.oauth,
      redirectSignIn: cleanLocation,
      redirectSignOut: cleanLocation,
    },
  };

  Amplify.configure(updatedAwsConfig);
}
