import { Router, RouteRecordRaw } from "vue-router";
import LoginView from "../auth/views/LoginView.vue";
import { useAuthStore } from "@/auth/store";

export const routes: Array<RouteRecordRaw> = [
  // ========== AUTH ==========
  {
    path: "/signin",
    name: "signin",
    component: LoginView,
  },
];

export function configureRouter(router: Router) {
  router.beforeEach(async (to, from, next) => {
    // Block the whole routes and require signin
    const authStore = useAuthStore();
    const is_auth = authStore.isAuthenticated;
    const dist_is_login = to.name === "signin";
    // If we want to go home and have query params, goes to home
    // This is because after login, we are redirected to home with a code query params that we do not want
    // user take care.
    if (to.name === "home" && to.query && Object.keys(to.query).length) {
      next(Object.assign({}, to, { query: {} }));
      return;
    }
    if (dist_is_login) {
      // If we want to go on the login route but already logged, go home
      if (is_auth) {
        next({ name: "home" });
        return;
      } else {
        // If we want to go to the login page, and we are not logged, let go to the logging page
        next();
        return;
      }
    } else if (is_auth) {
      // If we are logged we can go everywhere
      next();
      return;
    } else {
      // If we are not logged, redirect to signin
      next({ name: "signin" });
    }
  });
}
