import { RawProfile, rawProfiles } from "@/admin/services/rawProfiles";
import { groups } from "@/admin/services/groups";
import { User, users } from "@/admin/services/users";
import { removeDuplicates } from "@/shared/utils/set";
import { mapGather } from "@/shared/utils/async";
import {
  listAllPermissions,
  listLinkedPermissionsFromKeys,
} from "@/admin/services/permissions";

export interface Profile {
  name: string;
  description: string;
  permissionIds: string[];
  // userCount: number;
}
class ProfileManager {
  // General profile management
  async get(name: string): Promise<Profile | null> {
    if (groups.isProtected(name)) return null;
    //const rawGroups = await groups.list();
    //if (!rawGroups.includes(name)) return null;
    const rawProfile = await rawProfiles.get(name);
    return await this.getDetails(rawProfile);
  }

  async getDetails(rawProfile: RawProfile): Promise<Profile> {
    return {
      ...rawProfile,
      // userCount: await groups.countUser(rawProfile.name),
    };
  }
  async list(): Promise<Profile[]> {
    const rawProfilesAvailable = await rawProfiles.list();
    const rawGroups = await groups.list();
    return await mapGather(
      rawProfilesAvailable
        .filter((p) => !groups.isProtected(p.name))
        .filter((p) => rawGroups.includes(p.name)),
      (e) => this.getDetails(e)
    );
  }
  async create(
    name: string,
    description: string,
    permissionIds: string[]
  ): Promise<void> {
    if (groups.isProtected(name)) return;
    const rawGroups = await groups.list();
    if (!rawGroups.includes(name)) {
      await groups.create(name);
    }
    await rawProfiles.create(name, description, permissionIds);
  }
  async update(
    name: string,
    description: string,
    permissionIds: string[]
  ): Promise<void> {
    if (groups.isProtected(name)) return;
    const rawGroups = await groups.list();
    if (!rawGroups.includes(name)) {
      await groups.create(name);
    }
    await rawProfiles.update(name, description, permissionIds);
  }
  async delete(name: string): Promise<void> {
    if (groups.isProtected(name)) return;
    await groups.delete(name);
    await rawProfiles.delete(name);
  }

  // User management
  async addUserToProfile(user: User, group: string): Promise<void> {
    if (groups.isProtected(group)) return;
    const rawGroups = await groups.list();
    if (!rawGroups.includes(group)) return;
    await users.addToGroup(user, group);
  }

  async removeUserFromProfile(user: User, group: string) {
    if (groups.isProtected(group)) return;
    const rawGroups = await groups.list();
    if (!rawGroups.includes(group)) return;
    await users.removeFromGroup(user, group);
  }

  // Permission management
  async getUserPermissions(userGroups: string[]): Promise<string[]> {
    if (userGroups.includes("Developers")) {
      return listAllPermissions();
    }
    const result: string[] = [];
    for (const group of userGroups) {
      if (groups.isProtected(group)) continue;
      const perms = await this.get(group);
      if (perms && perms.permissionIds) {
        result.push(...listLinkedPermissionsFromKeys(perms.permissionIds));
      }
    }
    return removeDuplicates(result);
  }
}
export const profiles = new ProfileManager();
