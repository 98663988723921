import { AppNavigation } from "@/shared/services/navigation";

/** Define the navigation hierarchy */
export const telephonyNavigationConfig: AppNavigation = {
  title: "Telephony",
  icon: "phone",
  items: [
    {
      title: "Routing fields",
      items: [
        {
          title: "Countries",
          link: "telephony-routing-countries",
          icon: "flag",
        },
        {
          title: "Global Aggregates",
          link: "telephony-routing-global-aggregates",
          icon: "collections_bookmark",
        },
        {
          title: "Business Aggregates",
          link: "telephony-routing-source-aggregates",
          icon: "bookmarks",
        },
        {
          title: "Callback-sources",
          link: "telephony-routing-sources",
          icon: "bookmark",
        },
        {
          title: "Phone Number pools",
          link: "telephony-routing-phone-numbers-pool",
          icon: "phone",
        },
      ],
    },
    {
      title: "Routing admin",
      items: [
        {
          title: "Callback-sources qualification",
          link: "telephony-routing-sources-qualif",
          icon: "bookmark_added",
        },
        {
          title: "Campaigns equivalencies",
          link: "telephony-routing-campaigns-equivalencies",
          icon: "phone_callback",
        },
        {
          title: "Diabolocom campaigns automations",
          link: "telephony-routing-dc-campaigns-automations",
          icon: "auto_awesome",
        },
        {
          title: "Outbound campaign qualification",
          link: "telephony-routing-outbound-campaign-qualification-rules",
          icon: "phone_forwarded",
        },
        {
          title: "Regionalization qualification",
          link: "telephony-routing-regionalization-qualification-rules",
          icon: "dialpad",
        },
      ],
    },
    {
      title: "Timeslots",
      items: [
        {
          title: "Timeslots aggregates",
          link: "telephony-timeslots-aggregates",
          icon: "date_range",
        },
      ],
    },
    {
      title: "Callback request",
      items: [
        {
          title: "Logs",
          link: "telephony-routing-callback-request-logs",
          icon: "database",
        },
        {
          title: "Simulate campaigns equivalencies matching",
          link: "telephony-routing-simulate-matching-campaigns-equivalencies",
          icon: "join_inner",
        },
        {
          title: "Simulate send",
          link: "telephony-routing-simulate-callback-request",
          icon: "settings_phone",
        },
        {
          title: "Send",
          link: "telephony-routing-process-callback-request",
          icon: "phone_forwarded",
        },
      ],
    },
    {
      title: "Configuration",
      items: [
        {
          title: "Inbound services configuration",
          link: "telephony-config-inbound-services",
          icon: "swap_calls",
        },
        {
          title: "Archi Telephony",
          link: "telephony-config-archi-telephony",
          icon: "apartment",
        },
        {
          title: "SMS Forwarding",
          link: "telephony-config-sms-forwarding",
          icon: "forward_to_inbox",
        },
        {
          title: "Blacklisted numbers",
          link: "telephony-config-blacklist",
          icon: "phone_disabled",
        },
        {
          title: "Feature flags",
          link: "telephony-config-dynamic-properties",
          icon: "settings",
        },
      ],
    },
    {
      title: "Tools",
      items: [
        {
          title: "Diabolocom",
          link: "telephony-tools-diabolocom",
          icon: "precision_manufacturing",
        },
        {
          title: "Alerts report",
          link: "telephony-alert-report",
          icon: "report",
        },
        {
          title: "Calls history",
          link: "telephony-calls-history",
          icon: "history",
        },
        {
          title: "Planned-callbacks",
          link: "telephony-planned-callbacks",
          icon: "event_upcoming",
        },
      ],
    },
  ],
};
