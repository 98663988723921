/**
 * "Array as set" utilities
 */

/** Provide the intersection of to array as set */
export function intersection<Type>(array1: Type[], array2: Type[]): Type[] {
  return array1.filter((value) => array2.includes(value));
}

/** Check if two array as set intersects */
export function setIntersects<Type>(array1: Type[], array2: Type[]): boolean {
  return !!intersection(array1, array2).length;
}

export function setEquals<Type>(array1: Type[], array2: Type[]): boolean {
  array1 = removeDuplicates(array1);
  array2 = removeDuplicates(array2);
  if (array1.length !== array2.length) return false;
  return array1.every((e) => array2.includes(e));
}
export function removeDuplicates<Type>(array: Type[]): Type[] {
  const unique = [];
  for (const v of array) {
    if (unique.indexOf(v) === -1) {
      unique.push(v);
    }
  }
  return unique;
}
