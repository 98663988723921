import { useAuthStore } from "@/auth/store";

export interface NavigationItem {
  title: string;
  link?: string;
  icon?: string;
  needGroup?: string;
}
export interface NavigationGroup {
  title: string;
  items: NavigationItem[];
}
export interface AppNavigation {
  title: string;
  icon: string;
  needGroup?: string;
  items: NavigationGroup[];
}

export function userShouldSeeItem(item: NavigationItem): boolean {
  const authStore = useAuthStore();
  return !!(
    item.link &&
    (item.needGroup
      ? authStore.isInGroup(item.needGroup)
      : authStore.hasPermissionToPage(item.link))
  );
}

export function userShouldSeeGroup(group: NavigationGroup): boolean {
  for (const item of group.items) {
    if (userShouldSeeItem(item)) {
      return true;
    }
  }
  return false;
}

export function userShouldSeeApp(app: AppNavigation): boolean {
  const authStore = useAuthStore();
  if (app.needGroup) {
    return authStore.isInGroup(app.needGroup);
  }
  for (const group of app.items) {
    if (userShouldSeeGroup(group)) return true;
  }
  return false;
}
