/**
 * User location utilities
 */

/**
 * true if code run locally
 * (extracted from amplify doc)
 */
export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
/** Clean root current location */
export const cleanLocation =
  `${window.location.protocol}//${window.location.hostname}` +
  (window.location.port ? `:${window.location.port}` : "") +
  "/";
