import { telephonyNavigationConfig } from "@/telephony/nav";
import { adminNavigationConfig } from "@/admin/nav";
import { AppNavigation } from "@/shared/services/navigation";
import { offerCatalogNavigationConfig } from "@/offer-catalog/nav";

/** Define the navigation hierarchy */
export const navigationConfig: AppNavigation[] = [
  telephonyNavigationConfig,
  offerCatalogNavigationConfig,
  adminNavigationConfig,
];
