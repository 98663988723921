import { RouteRecordRaw } from "vue-router";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "users",
    name: "admin-users",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/UserView.vue"),
  },
  {
    path: "groups",
    name: "admin-groups",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/ProfileView.vue"),
  },
];
