/**
 * Auth-store manage authentication related domain function.
 * Mainly a convenient wrapper around amplify own auth logic
 */
import { PROTECTED_GROUPS } from "@/admin/services/groups";
import { getModuleFromRouterName } from "@/admin/services/permissions";
import { profiles } from "@/admin/services/profiles";
import { currentUser, updateCurrentUser } from "@/auth/services";
import router from "@/shared/router";
import { setIntersects } from "@/shared/utils/set";
import { Auth } from "aws-amplify";
import { defineStore } from "pinia";

export interface UserState {
  // User email
  email: string | null;
  // user groups
  _groups: string[];
  // permissions:
  appPermissions: string[];
  simulateGroup: string | null;
  simulatePermissions: string[];
}

export const useAuthStore = defineStore("auth", {
  state: function (): UserState {
    return {
      email: currentUser.email,
      _groups: currentUser.groups,
      appPermissions: [],
      simulateGroup: null,
      simulatePermissions: [],
    };
  },
  getters: {
    groups: (state) =>
      state.simulateGroup ? [state.simulateGroup] : state._groups,
    permissions: (state) =>
      state.simulateGroup ? state.simulatePermissions : state.appPermissions,

    /** true if the user is currently authenticated */
    isAuthenticated: (state) => !!state.email,
    isInGroup(): (name: string) => boolean {
      return (name: string) => this.isInDevGroup || this.groups.includes(name);
    },
    isInDevGroup(): boolean {
      return this.groups.includes("Developers");
    },
    isInUserAdminGroup(): boolean {
      return this.isInGroup("UserAdmin");
    },
    isInOfferCatalogGroup(): boolean {
      return this.isInGroup("OfferCatalog");
    },
    hasPermission(): (permission: string) => boolean {
      return (permission: string) => {
        if (this.isInDevGroup) return true;
        const currentRouteName = router.currentRoute.value.name;
        if (!currentRouteName) return false;
        const currentModuleFound = getModuleFromRouterName(
          currentRouteName.toString()
        );
        if (!currentModuleFound) return false;
        const [currentAppPerm, currentModulePerm] = currentModuleFound;

        return this.hasDetailedPermission(
          currentAppPerm.name,
          currentModulePerm.name,
          permission
        );
      };
    },
    hasPermissionToPage(): (routerName: string) => boolean {
      return (routerName: string) => {
        if (this.isInDevGroup) return true;
        const moduleFound = getModuleFromRouterName(routerName);
        if (!moduleFound) return false;
        const [appPerm, modulePerm] = moduleFound;
        for (const perm of modulePerm.permissions) {
          if (
            this.hasDetailedPermission(appPerm.name, modulePerm.name, perm.name)
          ) {
            return true;
          }
        }
        return false;
      };
    },
    hasDetailedPermission(): (
      app: string,
      page: string,
      permission: string
    ) => boolean {
      return (app: string, page: string, permission: string) =>
        this.isInDevGroup
          ? true
          : setIntersects(this.permissions, [
              `${app}.${page}.${permission}`,
              `${app}.${page}.*`,
              `${app}.*.*`,
            ]);
    },
    isInSimulationMode: (state) => state.simulateGroup !== null,
    groupSimulationName: (state) => state.simulateGroup || "unknown",
  },
  actions: {
    /** logout the user **/
    async logout() {
      await Auth.signOut();
      this.$patch(await updateCurrentUser());
    },
    async retrieveUserPermissions() {
      if (!this.email) {
        this.appPermissions = [];
        return;
      }
      try {
        this.appPermissions = await profiles.getUserPermissions(this._groups);
      } catch (e) {
        await this.logout();
        this.appPermissions = [];
      }
    },
    async simulateAs(groupName: string) {
      if (PROTECTED_GROUPS.includes(groupName)) return;
      this.simulateGroup = groupName;
      this.simulatePermissions = await profiles.getUserPermissions([groupName]);
      await router.replace({ name: "home" });
    },
    async stopSimulate() {
      this.simulateGroup = null;
      this.simulatePermissions = [];
      await router.replace({ name: "home" });
    },
    async ensurePageAccess() {
      if (this.isInDevGroup) return;
      const currentRouteName = router.currentRoute.value.name;
      if (
        !currentRouteName ||
        !this.hasPermissionToPage(currentRouteName.toString())
      ) {
        await router.replace({ name: "home" });
      }
    },
    async ensureSpecificPermission(
      app: string,
      page: string,
      permission: string
    ) {
      if (this.isInDevGroup) return;
      if (!this.hasDetailedPermission(app, page, permission)) {
        await router.replace({ name: "home" });
      }
    },
  },
});
