import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "./views/HomeView.vue";
import { routes as telephony_routes } from "@/telephony/router";
import { configureRouter, routes as auth_routes } from "@/auth/router";
import { routes as admin_routes } from "@/admin/router";
import { routes as offer_catalog_routes } from "@/offer-catalog/router";
import { telephony_url_prefix } from "./utils/amplify";
import { userShouldSeeApp } from "./services/navigation";
import { offerCatalogNavigationConfig } from "@/offer-catalog/nav";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // ========== AUTH ==========
  {
    path: "/auth",
    children: auth_routes,
  },
  // ========== ADMIN ==========
  {
    path: "/admin",
    children: admin_routes,
  },
  // ========== TELEPHONY ==========
  {
    path: `${telephony_url_prefix}`,
    children: telephony_routes,
  },
  // ========== OFFER CATALOG ==========
  {
    path: "/offer-catalog",
    children: offer_catalog_routes,
    beforeEnter() {
      return userShouldSeeApp(offerCatalogNavigationConfig);
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
configureRouter(router);

export default router;
