import { createApp } from "vue";
import App from "./shared/views/App.vue";
import router from "./shared/router";
import AmplifyVue from "@aws-amplify/ui-vue";
import { createPinia } from "pinia";
import vClickOutside from "./shared/utils/click-outside";
import { configureAmplify } from "@/shared/utils/amplify";
import { configureSentry } from "@/shared/utils/sentry";
import VueApexCharts from "vue3-apexcharts";
import { VueMasonryPlugin } from "vue-masonry";
import { updateCurrentUser } from "@/auth/services";
import { useAuthStore } from "@/auth/store";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@/assets/css/style.css";

const toastOptions = {
  position: "top-center",
  maxToasts: 2,
  hideProgressBar: true,
  transition: "Vue-Toastification__bounce",
  newestOnTop: true,
};
// First, ensure amplify is correctly setup
configureAmplify();
// Then, we retrieve currently connected user (if connected) and wait for it
updateCurrentUser().then(() => {
  // Create the vue app
  const app = createApp(App)
    .use(createPinia().use(piniaPluginPersistedstate))
    .use(router)
    .use(AmplifyVue)
    .use(VueApexCharts)
    .use(VueMasonryPlugin)
    .use(Toast, toastOptions);
  configureSentry(app, router);
  app.directive("clickOutside", vClickOutside);
  // Now all is setup, retrieve the user permissions before mounting the app
  const auth = useAuthStore();
  auth.retrieveUserPermissions().then(() => {
    app.mount("#app");
  });
});
