import { useAuthStore } from '@/auth/store';
import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
    // ===== ENERGY OFFERS =====
    {
        path: 'offers/energy',
        name: 'offer-catalog-routing-energy',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/energy/listEnergyOffers.vue'
            ),
    },
    {
        path: 'offers/energy/:id',
        name: 'offer-catalog-routing-energy-details',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/energy/detailsEnergyOffer.vue'
            ),
    },
    {
        path: 'offers/energy/create',
        name: 'offer-catalog-routing-energy-create',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/energy/createEnergyOffer.vue'
            ),
        beforeEnter() {
            const auth = useAuthStore();
            return auth.isInOfferCatalogGroup;
        },
    },

    // ===== BOX OFFERS =====
    {
        path: 'offers/box',
        name: 'offer-catalog-routing-box',
        component: () =>
            import(/* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/box/listBoxOffers.vue'),
    },
    {
        path: 'offers/box/:id',
        name: 'offer-catalog-routing-box-details',
        component: () =>
            import(/* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/box/detailsBoxOffer.vue'),
    },
    {
        path: 'offers/box/create',
        name: 'offer-catalog-routing-box-create',
        component: () =>
            import(/* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/box/createBoxOffer.vue'),
        beforeEnter() {
            const auth = useAuthStore();
            return auth.isInOfferCatalogGroup;
        },
    },

    // ===== CELLULAR OFFERS =====
    {
        path: 'offers/cellular',
        name: 'offer-catalog-routing-cellular',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/cellular/listCellularOffers.vue'
            ),
    },
    {
        path: 'offers/cellular/:id',
        name: 'offer-catalog-routing-cellular-details',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/cellular/detailsCellularOffer.vue'
            ),
    },
    {
        path: 'offers/cellular/create',
        name: 'offer-catalog-routing-cellular-create',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/cellular/createCellularOffer.vue'
            ),
        beforeEnter() {
            const auth = useAuthStore();
            return auth.isInOfferCatalogGroup;
        },
    },

    // ===== TV OFFERS =====
    {
        path: 'offers/tv/',
        name: 'offer-catalog-routing-tv',
        component: () =>
            import(/* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/tv/listTVOffers.vue'),
    },
    {
        path: 'offers/tv/:id',
        name: 'offer-catalog-routing-tv-details',
        component: () =>
            import(/* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/tv/detailsTVOffer.vue'),
    },
    {
        path: 'offers/tv/create',
        name: 'offer-catalog-routing-tv-create',
        component: () =>
            import(/* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/tv/createTVOffer.vue'),
        beforeEnter() {
            const auth = useAuthStore();
            return auth.isInOfferCatalogGroup;
        },
    },

    // ===== HOME ASSISTANCE OFFERS =====
    {
        path: 'offers/home_assistance',
        name: 'offer-catalog-routing-home-assistance',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/home_assistance/listHomeAssistanceOffers.vue'
            ),
    },
    {
        path: 'offers/home_assistance/:id',
        name: 'offer-catalog-routing-home-assistance-details',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/home_assistance/detailsHomeAssistanceOffer.vue'
            ),
    },
    {
        path: 'offers/home_assistance/create',
        name: 'offer-catalog-routing-home-assistance-create',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/home_assistance/createHomeAssistance.vue'
            ),
        beforeEnter() {
            const auth = useAuthStore();
            return auth.isInOfferCatalogGroup;
        },
    },

    {
        path: 'offers/home_insurance/',
        name: 'offer-catalog-routing-home-insurance',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/home_insurance/listHomeInsuranceOffers.vue'
            ),
    },
    {
        path: 'offers/home_insurance/:id',
        name: 'offer-catalog-routing-home-insurance-details',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/home_insurance/detailsHomeInsuranceOffer.vue'
            ),
    },
    {
        path: 'offers/home_insurance/create',
        name: 'offer-catalog-routing-home-insurance-create',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/home_insurance/createHomeInsuranceOffer.vue'
            ),
        beforeEnter() {
            const auth = useAuthStore();
            return auth.isInOfferCatalogGroup;
        },
    },

    {
        path: 'offers/home_maintenance/',
        name: 'offer-catalog-routing-home-maintenance',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/home_maintenance/listHomeMaintenanceOffers.vue'
            ),
    },
    {
        path: 'offers/home_maintenance/:id',
        name: 'offer-catalog-routing-home-maintenance-details',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/home_maintenance/detailsHomeMaintenanceOffer.vue'
            ),
    },
    {
        path: 'offers/home_maintenance/create',
        name: 'offer-catalog-routing-home-maintenance-create',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/home_maintenance/createHomeMaintenance.vue'
            ),
        beforeEnter() {
            const auth = useAuthStore();
            return auth.isInOfferCatalogGroup;
        },
    },
    {
        path: 'offers/bundle_box_cellular',
        name: 'offer-catalog-routing-bundle-box-cellular',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/bundle_box_cellular/listBundleBoxCellularOffers.vue'
            ),
    },
    {
        /* A FAIRE MAIS POUR L'INSTANT PAS D'OFFRES DISPO */
        path: 'offers/bundle_box_cellular/:id',
        name: 'offer-catalog-routing-bundle-box-cellular-details',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/bundle_box_cellular/listBundleBoxCellularOffers.vue'
            ),
    },

    /* A FINIR PROBLEME DE NOMS*/
    {
        path: 'offers/plan',
        name: 'offer-catalog-routing-plan',
        component: () =>
            import(/* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/plan/listPlan.vue'),
    },
    {
        path: 'offers/plan/:id',
        name: 'offer-catalog-routing-plan-details',
        component: () =>
            import(/* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/plan/listPlan.vue'),
    },

    {
        path: 'offers/price_grid',
        name: 'offer-catalog-routing-price-grid',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/price_grids/listPriceGrids.vue'
            ),
    },
    {
        path: 'offers/price_grid/:id',
        name: 'offer-catalog-routing-price-grid-details',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/price_grids/detailPriceGrid.vue'
            ),
    },

    {
        path: 'offers/price/',
        name: 'offer-catalog-routing-price',
        component: () =>
            import(
                /* webpackChunkName: "offer-catalog-routing" */ '../offer-catalog/views/price_grids/listPriceGrids.vue'
            ),
    },
];
