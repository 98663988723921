import { Auth } from "aws-amplify";

export interface ConnectedUser {
  // User email
  email: string | null;
  // user groups
  groups: string[];
}

export let currentUser: ConnectedUser;

export async function updateCurrentUser(): Promise<ConnectedUser> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    currentUser = {
      email: user.attributes.email,
      groups: user.signInUserSession.accessToken.payload["cognito:groups"],
    };
  } catch (e) {
    currentUser = {
      email: null,
      groups: [],
    };
  }
  return currentUser;
}
