import { AppNavigation } from "@/shared/services/navigation";

export const adminNavigationConfig: AppNavigation = {
  title: "Admin",
  icon: "settings",
  needGroup: "UserAdmin",
  items: [
    {
      title: "Users and groups",
      items: [
        {
          title: "Users",
          link: "admin-users",
          icon: "manage_accounts",
          needGroup: "UserAdmin",
        },
        {
          title: "Groups",
          link: "admin-groups",
          icon: "groups",
          needGroup: "UserAdmin",
        },
      ],
    },
  ],
};
