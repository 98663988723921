import {
  addUserToGroup,
  disableUser,
  enableUser,
  listGroupsForUser,
  listUsers,
  rawUser,
  removeUserFromGroup,
} from "@/admin/services/adminQueries";
import { mapGather } from "@/shared/utils/async";
import { Profile, profiles } from "@/admin/services/profiles";
import { PROTECTED_GROUPS } from "@/admin/services/groups";

export interface User {
  active: boolean;
  email: string;
  groups: string[];
  internalUsername: string;
}
class UserManager {
  async list(): Promise<User[]> {
    const rawUsers = await listUsers();
    const availableProfiles = await profiles.list();
    return await mapGather(rawUsers, (e) =>
      this.getDetails(e, availableProfiles)
    );
  }

  async disable(user: User) {
    await disableUser(user.internalUsername);
  }
  async enable(user: User) {
    await enableUser(user.internalUsername);
  }
  async removeFromGroup(user: User, group: string) {
    await removeUserFromGroup(user.internalUsername, group);
  }
  async addToGroup(user: User, group: string) {
    await addUserToGroup(user.internalUsername, group);
  }
  private async getDetails(
    rUser: rawUser,
    availableProfiles: Profile[]
  ): Promise<User> {
    const email = rUser.Attributes.filter((e) => e.Name === "email").map(
      (e) => e.Value
    )[0];

    const rawUserGroups = await listGroupsForUser(rUser.Username);
    const allowedGroups = [
      ...PROTECTED_GROUPS,
      ...availableProfiles.map((p) => p.name),
    ];

    const groups = rawUserGroups
      .map((e) => e.GroupName)
      .filter((g) => allowedGroups.includes(g))
      .sort();

    return {
      active: rUser.Enabled,
      email: email,
      groups: groups,
      internalUsername: rUser.Username,
    };
  }
}
export const users = new UserManager();
