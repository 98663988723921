import {
  AppPermission,
  ModulePermission,
  Permission,
  UUID_RE,
} from "@/auth/permissions";

export enum DcResources {
  CAMPAIGNS = "campaigns",
  SKILLS = "skills",
  MANUAL_OUTBOUNDS = "manual-outbounds",
  GROUPS = "groups",
  CALENDARS = "calendars",
  FILES = "files",
  SERVICES = "services",
  QUEUES = "queues",
  ACTIVITIES = "activities",
}

function permForDcResource(name: string): Permission {
  return {
    name: `Read ${name}`,
    description: `Allow read the ${name} Diabolocom resource`,
    routes: [{ method: "GET", path: `telephony/diabolocom/${name}` }],
  };
}
function listAllPermsForDcResource(): Permission[] {
  return Object.keys(DcResources).map((k) =>
    permForDcResource(DcResources[k as keyof typeof DcResources])
  );
}

function keyOfDcResource(res: DcResources): string {
  return `telephony.Diabolocom resources.Read ${res.toString()}`;
}
function keyOfReadAdminResource(name: string): string {
  return `telephony.${name}.Read`;
}
interface ResourcesElements {
  create?: boolean;
  update?: boolean;
  delete?: boolean;
}

function telephonyCRUDAdminPermissions(
  resourceName: string,
  resourcePath: string,
  disabled?: ResourcesElements | null,
  depDcResources?: DcResources[] | null,
  depOtherResources?: string[] | null
): Permission[] {
  disabled = disabled || {};
  const result: Permission[] = [];
  const readPerm: Permission = {
    name: "Read",
    description: `Allow read the ${resourceName} resource`,
    routes: [{ method: "GET", path: `telephony/${resourcePath}` }],
    dependOn: [
      ...(depDcResources || []).map(keyOfDcResource),
      ...(depOtherResources || []),
    ],
  };
  result.push(readPerm);
  const readPermName = `telephony.${resourceName}.Read`;
  if (!disabled.create) {
    result.push({
      name: "Create",
      description: `Allow create the ${resourceName} resource`,
      routes: [{ method: "POST", path: `telephony/${resourcePath}` }],
      dependOn: [readPermName],
    });
  }
  if (!disabled.update) {
    result.push({
      name: "Update",
      description: `Allow update the ${resourceName} resource`,
      routes: [
        { method: "PUT", re: `^/telephony/${resourcePath}/${UUID_RE}$` },
        {
          method: "PATCH",
          re: `^/telephony/${resourcePath}/${UUID_RE}$`,
        },
      ],
      dependOn: [readPermName],
    });
  }
  if (!disabled.delete) {
    result.push({
      name: "Delete",
      description: `Allow delete the ${resourceName} resource`,
      routes: [
        {
          method: "DELETE",
          re: `^/telephony/${resourcePath}/${UUID_RE}$`,
        },
      ],
      dependOn: [readPermName],
    });
  }
  return result;
}
function telephonyCRUDAdminPages(
  resourceName: string,
  resourcePath: string,
  routerName: string,
  routerDetailName?: string | null,
  disabled?: ResourcesElements | null,
  depDcResources?: DcResources[] | null,
  depOtherResources?: string[] | null,
  importExportParam?: string
): ModulePermission[] {
  const result: ModulePermission[] = [
    {
      name: resourceName,
      description: `${resourceName} management page`,
      routerName: routerName,
      permissions: telephonyCRUDAdminPermissions(
        resourceName,
        resourcePath,
        disabled,
        depDcResources,
        depOtherResources
      ),
    },
  ];
  if (routerDetailName) {
    const readPerm = `telephony.${resourceName}.Read`;
    const perm: ModulePermission = {
      name: `${resourceName} details`,
      description: `${resourceName} details management page`,
      routerName: routerDetailName,
      permissions: [
        {
          name: "Read",
          description: `Allow read the details of ${resourceName} resource`,
          routes: [
            {
              method: "GET",
              re: `^/telephony/${resourcePath}/${UUID_RE}/versions$`,
            },
          ],
          dependOn: [
            readPerm,
            ...(depDcResources || []).map(keyOfDcResource),
            ...(depOtherResources || []),
          ],
        },
      ],
    };
    if (importExportParam) {
      perm.permissions.push(
        {
          name: "Import CSV",
          description: "Allow import CSV",
          routes: [
            {
              method: "POST",
              path: `/telephony/${importExportParam}/import`,
            },
          ],
        },
        {
          name: "Export CSV",
          description: "Allow export CSV",
          routes: [
            {
              method: "GET",
              path: `/telephony/${importExportParam}/export`,
            },
          ],
        }
      );
    }
    result.push(perm);
  }

  return result;
}

export const permissions: AppPermission = {
  name: "telephony",
  description: "Telephony related permissions",
  modules: [
    {
      name: "Diabolocom resources",
      description: "Allow to list Diabolocom resources",
      permissions: listAllPermsForDcResource(),
    },
    ...telephonyCRUDAdminPages(
      "Countries",
      "rules/admin/countries",
      "telephony-routing-countries",
      "telephony-routing-countries-detail"
    ),
    ...telephonyCRUDAdminPages(
      "Global Aggregates",
      "rules/admin/global-aggregates",
      "telephony-routing-global-aggregates",
      "telephony-routing-global-aggregates-detail"
    ),
    ...telephonyCRUDAdminPages(
      "Business Aggregates",
      "rules/admin/source-aggregates",
      "telephony-routing-source-aggregates",
      "telephony-routing-source-aggregates-detail",
      null,
      null,
      [
        keyOfReadAdminResource("Countries"),
        keyOfReadAdminResource("Global Aggregates"),
      ]
    ),
    ...telephonyCRUDAdminPages(
      "Callbacks sources",
      "rules/admin/sources",
      "telephony-routing-sources",
      "telephony-routing-sources-detail",
      null,
      null,
      [
        keyOfReadAdminResource("Countries"),
        keyOfReadAdminResource("Business Aggregates"),
      ]
    ),
    ...telephonyCRUDAdminPages(
      "Campaigns equivalencies",
      "rules/admin/campaign-equivalencies",
      "telephony-routing-campaigns-equivalencies",
      "telephony-routing-campaign-equivalencies-detail",
      null,
      [DcResources.CAMPAIGNS],
      [
        keyOfReadAdminResource("Countries"),
        keyOfReadAdminResource("Business Aggregates"),
        keyOfReadAdminResource("Callbacks sources"),
      ]
    ),
    ...telephonyCRUDAdminPages(
      "Diabolocom campaigns automations",
      "rules/admin/dc-campaigns-automations",
      "telephony-routing-dc-campaigns-automations",
      "telephony-routing-dc-campaigns-automations-detail",
      null,
      [DcResources.CAMPAIGNS]
    ),
    ...telephonyCRUDAdminPages(
      "Outbound campaign qualification",
      "rules/admin/campaign-routing",
      "telephony-routing-outbound-campaign-qualification-rules",
      null,
      null,
      [DcResources.CAMPAIGNS]
    ),
    ...telephonyCRUDAdminPages(
      "Phone Numbers pool",
      "/rules/admin/phone-number-pool",
      "telephony-routing-phone-numbers-pool",
      "telephony-routing-phone-numbers-pool-detail"
    ),

    {
      name: "Simulate campaigns equivalencies matching",
      description: "Simulate campaigns equivalencies matching page",
      routerName: "telephony-routing-simulate-matching-campaigns-equivalencies",
      permissions: [
        {
          name: "Simulate",
          description: "Allow simulate campaigns equivalencies matching",
          routes: [
            {
              method: "POST",
              path: "/telephony/rules/simulate/campaign-equivalency-matching",
            },
          ],
          dependOn: [keyOfReadAdminResource("Campaigns equivalencies")],
        },
      ],
    },
    {
      name: "Simulate callback request send",
      description: "Simulate callback request send page",
      routerName: "telephony-routing-simulate-callback-request",
      permissions: [
        {
          name: "Simulate",
          description: "Allow simulate callback request send",
          routes: [
            {
              method: "POST",
              path: "/telephony/rules/simulate/callback-request",
            },
          ],
        },
      ],
    },
    {
      name: "Send callback request",
      description: "Send callback request page",
      routerName: "telephony-routing-process-callback-request",
      permissions: [
        {
          name: "Send",
          description: `Allow send callback request`,
          routes: [{ method: "POST", path: "/telephony/callbacks/send" }],
        },
      ],
    },
    {
      name: "Callback request log",
      description: "Callback request log page",
      routerName: "telephony-routing-callback-request-logs",
      permissions: [
        {
          name: "Read",
          description: "Allow read the callback request log",
          routes: [{ method: "GET", path: "/telephony/callbacks/logs" }],
        },
      ],
    },
    {
      name: "Diabolocom tools",
      description: "Diabolocom tools page",
      routerName: "telephony-tools-diabolocom",
      permissions: [
        {
          name: "Add skill to group",
          description: "Allow add skill to a group",
          routes: [
            {
              method: "POST",
              path: "/telephony/diabolocom/add-skills-to-user-groups",
            },
            {
              method: "GET",
              re: `^/telephony/jobs/${UUID_RE}$`,
            },
          ],
          dependOn: [
            keyOfDcResource(DcResources.GROUPS),
            keyOfDcResource(DcResources.SKILLS),
          ],
        },
      ],
    },
    {
      name: "Calls history",
      description: "Calls history page",
      routerName: "telephony-calls-history",
      permissions: [
        {
          name: "Read",
          description: "Allow read the call history",
          routes: [{ method: "GET", path: "/telephony/call-history/v3/calls" }],
        },
      ],
    },
    {
      name: "Calls history detail",
      description: "Calls history detail page",
      routerName: "telephony-calls-history-detail",
      permissions: [
        {
          name: "Read",
          description: "Allow read the call history detail",
          routes: [
            {
              method: "GET",
              path: `telephony/call-history/v3/calls/from-internal-id/${UUID_RE}`,
            },
          ],
        },
        {
          name: "Assign call to client",
          description: "Allow assign a call to another client",
          routes: [
            {
              method: "POST",
              path: "/telephony/call-history/v3/calls/assign",
            },
          ],
        },
      ],
    },
    {
      name: "Alerts report",
      description: "See alerts report page",
      routerName: "telephony-alert-report",
      permissions: [
        {
          name: "Read",
          description: "Allow read the alert report",
          routes: [
            {
              method: "GET",
              path: "/telephony/rules/alerts/unavailable-campaigns",
            },
            {
              method: "GET",
              path: "/telephony/rules/alerts/unnamed-archi-telephony",
            },
          ],
          dependOn: [
            keyOfReadAdminResource("Campaigns equivalencies"),
            keyOfReadAdminResource("Archi Telephony"),
          ],
        },
      ],
    },
    ...telephonyCRUDAdminPages(
      "Inbound services configurations",
      "config/inbound-services",
      "telephony-config-inbound-services",
      "telephony-config-inbound-services-detail",
      null,
      [
        DcResources.FILES,
        DcResources.CALENDARS,
        DcResources.SKILLS,
        DcResources.ACTIVITIES,
        DcResources.SERVICES,
      ],
      [keyOfReadAdminResource("Countries")],
      "inbound-conf"
    ),
    ...telephonyCRUDAdminPages(
      "Archi Telephony",
      "config/archi-telephony",
      "telephony-config-archi-telephony",
      null,
      { create: false, delete: false },
      [
        DcResources.CAMPAIGNS,
        DcResources.SERVICES,
        DcResources.SKILLS,
        DcResources.MANUAL_OUTBOUNDS,
      ],
      [
        keyOfReadAdminResource("Countries"),
        keyOfReadAdminResource("Global Aggregates"),
      ]
    ),
    ...telephonyCRUDAdminPages(
      "SMS Forwarding",
      "sms/sms-forwarding",
      "telephony-config-sms-forwarding"
    ),
    ...telephonyCRUDAdminPages(
      "Blacklisted numbers",
      "blacklist/admin",
      "telephony-config-blacklist"
    ),
    ...telephonyCRUDAdminPages(
      "Dynamic properties",
      "config/dynamic-properties",
      "telephony-config-dynamic-properties",
      "telephony-config-dynamic-properties-detail"
    ),
    ...telephonyCRUDAdminPages(
      "Timeslots aggregates",
      "time-slots/aggregates",
      "telephony-timeslots-aggregates",
      "telephony-timeslots-aggregates-detail",
      null,
      null,
      [keyOfReadAdminResource("Countries")]
    ),
    {
      name: "Timeslots",
      description: "Timeslots management page",
      routerName: "telephony-timeslots-details",
      permissions: [
        {
          name: "Read",
          description: `Allow read the Timeslot resource`,
          routes: [{ method: "GET", path: "/telephony/time-slots/search" }],
          dependOn: [keyOfReadAdminResource("Timeslots aggregates")],
        },
        {
          name: "Create",
          description: "Allow create the Timeslot resource",
          routes: [
            { method: "POST", path: "/telephony/time-slots/admin/" },
            { method: "GET", path: "/telephony/time-slots/availability" },
          ],
          dependOn: ["telephony.Timeslots.Read"],
        },
        {
          name: "Update",
          description: "Allow update the Timeslot resource",
          routes: [
            {
              method: "PATCH",
              re: `^/telephony/time-slots/admin/${UUID_RE}$`,
            },
            { method: "GET", path: "/telephony/time-slots/availability" },
          ],
          dependOn: ["telephony.Timeslots.Read"],
        },
        {
          name: "Delete",
          description: "Allow delete the Timeslot resource",
          routes: [
            {
              method: "DELETE",
              re: `^/telephony/time-slots/admin/${UUID_RE}$`,
            },
          ],
          dependOn: ["telephony.Timeslots.Read"],
        },
        {
          name: "Import CSV",
          description: "Allow import CSV into Timeslots",
          routes: [
            {
              method: "POST",
              path: "/telephony/import-export/import/timeslot",
            },
            {
              method: "POST",
              path: "/telephony/import-export/import/inbound-conf",
            },
            {
              method: "POST",
              path: "/telephony/import-export/import/source",
            },
            {
              method: "POST",
              path: "/telephony/import-export/import/source-qualif-rule",
            },
          ],
          dependOn: ["telephony.Timeslots.Read"],
        },
        {
          name: "Export CSV",
          description: "Allow export CSV from Timeslots",
          routes: [
            {
              method: "GET",
              path: "/telephony/import-export/export/timeslot",
            },
            {
              method: "GET",
              path: "/telephony/import-export/export/inbound-conf",
            },
            {
              method: "GET",
              path: "/telephony/import-export/export/source",
            },
            {
              method: "GET",
              path: "/telephony/import-export/export/source-qualif-rule",
            },
          ],
          dependOn: ["telephony.Timeslots.Read"],
        },
      ],
    },
    {
      name: "Planned-callbacks",
      description: "Planned-callbacks management page",
      routerName: "telephony-planned-callbacks",
      permissions: [
        {
          name: "Read",
          description: `Allow read the Planned-callbacks`,
          routes: [
            { method: "GET", path: "/telephony/planned-callbacks/" },
            {
              method: "GET",
              re: `^/telephony/call-history/v3/clients/${UUID_RE}$`,
            },
          ],
          dependOn: [
            keyOfDcResource(DcResources.CAMPAIGNS),
            keyOfReadAdminResource("Countries"),
            keyOfReadAdminResource("Business Aggregates"),
            keyOfReadAdminResource("Callbacks sources"),
          ],
        },
        {
          name: "Refresh callback",
          description: `Allow refresh a planned-callback`,
          routes: [
            {
              method: "POST",
              re: `^/telephony/planned-callbacks/refresh/${UUID_RE}$`,
            },
          ],
        },
      ],
    },
    {
      name: "Callback-Source qualification",
      description: "Callback-Source qualification",
      routerName: "telephony-routing-sources-qualif",
      permissions: [
        {
          name: "Read",
          description: `Allow read the Callback-Source qualification`,
          routes: [
            { method: "GET", path: "/telephony/source-qualification/rules" },
          ],
          dependOn: [keyOfReadAdminResource("Callbacks sources")],
        },
        {
          name: "Update order",
          description: "Allow update the Callback-Source resource orders",
          routes: [
            {
              method: "PATCH",
              re: `^/telephony/source-qualification/rules/${UUID_RE}$`,
            },
          ],
          dependOn: ["telephony.Callback-Source qualification.Read"],
        },
        {
          name: "Delete",
          description: "Allow delete the Callback-Source resource",
          routes: [
            {
              method: "DELETE",
              re: `^/telephony/source-qualification/rules/${UUID_RE}$`,
            },
          ],
          dependOn: ["telephony.Callback-Source qualification.Read"],
        },
      ],
    },
    {
      name: "Callback-Source qualification Creation",
      description: "Callback-Source qualification creation page",
      routerName: "telephony-routing-sources-qualif-create",
      permissions: [
        {
          name: "Create",
          description: "Allow create the Timeslot resource",
          routes: [
            { method: "POST", path: "/telephony/source-qualification/rules/" },
          ],
          dependOn: ["telephony.Callback-Source qualification.Read"],
        },
      ],
    },
    {
      name: "Callback-Source qualification Edit",
      description: "Callback-Source qualification update page",
      routerName: "telephony-routing-sources-qualif-edit",
      permissions: [
        {
          name: "Update",
          description: "Allow update the Timeslot resource",
          routes: [
            {
              method: "PATCH",
              re: `^/telephony/source-qualification/rules/${UUID_RE}$`,
            },
          ],
          dependOn: ["telephony.Callback-Source qualification.Read"],
        },
      ],
    },
    {
      name: "Outbound campaign qualification",
      description: "Outbound campaign qualification",
      routerName: "telephony-routing-outbound-campaign-qualification-rules",
      permissions: [
        {
          name: "Read",
          description: `Allow read the Outbound campaign qualification`,
          routes: [
            {
              method: "GET",
              path: "/telephony/outbound-campaign-qualification/rules",
            },
          ],
          dependOn: [keyOfReadAdminResource("Callbacks sources")],
        },
        {
          name: "Update order",
          description:
            "Allow update the Outbound campaign qualification resource orders",
          routes: [
            {
              method: "PATCH",
              re: `^/telephony/outbound-campaign-qualification/rules/${UUID_RE}$`,
            },
          ],
          dependOn: ["telephony.Outbound campaign qualification.Read"],
        },
        {
          name: "Delete",
          description: "Allow delete the Outbound campaign qualification",
          routes: [
            {
              method: "DELETE",
              re: `^/telephony/outbound-campaign-qualification/rules/${UUID_RE}$`,
            },
          ],
          dependOn: ["telephony.Outbound campaign qualification.Read"],
        },
      ],
    },
    {
      name: "Outbound campaign qualification Creation",
      description: "Outbound campaign qualification creation page",
      routerName:
        "telephony-routing-outbound-campaign-qualification-rules-create",
      permissions: [
        {
          name: "Create",
          description: "Allow create the Outbound campaign qualification rule",
          routes: [
            {
              method: "POST",
              path: "/telephony/outbound-campaign-qualification/rules",
            },
          ],
          dependOn: ["telephony.Outbound campaign qualification.Read"],
        },
      ],
    },
    {
      name: "Outbound campaign qualification Edit",
      description: "Outbound campaign qualification update page",
      routerName:
        "telephony-routing-outbound-campaign-qualification-rules-edit",
      permissions: [
        {
          name: "Update",
          description: "Allow update the Outbound campaign qualification rule",
          routes: [
            {
              method: "PATCH",
              re: `^/telephony/outbound-campaign-qualification/rules/${UUID_RE}$`,
            },
          ],
          dependOn: ["telephony.Outbound campaign qualification.Read"],
        },
      ],
    },
    {
      name: "Regionalization qualification",
      description: "Regionalization qualification",
      routerName: "telephony-routing-regionalization-qualification-rules",
      permissions: [
        {
          name: "Read",
          description: `Allow read the Regionalization qualification rules`,
          routes: [
            {
              method: "GET",
              path: "/telephony/regionalization-qualification/rules",
            },
          ],
          dependOn: [keyOfReadAdminResource("Archi Telephony")],
        },
        {
          name: "Update order",
          description:
            "Allow update the Regionalization qualification rules order",
          routes: [
            {
              method: "PATCH",
              re: `^/telephony/regionalization-qualification/rules/${UUID_RE}$`,
            },
          ],
          dependOn: ["telephony.Regionalization qualification.Read"],
        },
        {
          name: "Delete",
          description: "Allow delete the Regionalization qualification rules",
          routes: [
            {
              method: "DELETE",
              re: `^/telephony/regionalization-qualification/rules/${UUID_RE}$`,
            },
          ],
          dependOn: ["telephony.Regionalization qualification.Read"],
        },
      ],
    },
    {
      name: "Regionalization qualification Creation",
      description: "Regionalization qualification creation page",
      routerName:
        "telephony-routing-regionalization-qualification-rules-create",
      permissions: [
        {
          name: "Create",
          description: "Allow create the Regionalization qualification rule",
          routes: [
            {
              method: "POST",
              path: "/telephony/regionalization-qualification/rules",
            },
          ],
          dependOn: ["telephony.Regionalization qualification.Read"],
        },
      ],
    },
    {
      name: "Regionalization qualification Edit",
      description: "Regionalization qualification update page",
      routerName: "telephony-routing-regionalization-qualification-rules-edit",
      permissions: [
        {
          name: "Update",
          description: "Allow update the Regionalization qualification rule",
          routes: [
            {
              method: "PATCH",
              re: `^/telephony/regionalization-qualification/rules/${UUID_RE}$`,
            },
          ],
          dependOn: ["telephony.Regionalization qualification.Read"],
        },
      ],
    },
  ],
};
