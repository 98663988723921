/**
 * Async tools
 */

/**
 * A simple sleep async-compatible function
 * @param ms time, in ms, to sleep
 */
export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function mapGather<T, R>(
  elements: T[],
  fn: (e: T) => Promise<R>
): Promise<R[]> {
  const promises = [];
  for (const e of elements) {
    promises.push(fn(e));
  }
  return await Promise.all(promises);
}
