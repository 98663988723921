import {
  createGroup,
  deleteGroup,
  listGroups,
  listUsersInGroup,
} from "@/admin/services/adminQueries";

export const PROTECTED_GROUPS = ["Developers", "UserAdmin"];
export const PROTECTED_GROUPS_DESCRIPTION = {
  Developers:
    "(Special) have access to the user admin page and then can provide new access to other users.",
  UserAdmin:
    "(Special) have access to internal admin panel page to monitor the services. No business related features.",
};

class GroupManager {
  async list(): Promise<string[]> {
    const rawGroupsResult = await listGroups();
    return rawGroupsResult.map((e) => e.GroupName);
  }
  async countUser(name: string): Promise<number> {
    return (await listUsersInGroup(name)).length;
  }
  async create(name: string): Promise<void> {
    await createGroup(name);
  }
  async delete(name: string): Promise<void> {
    await deleteGroup(name);
  }

  isProtected(name: string): boolean {
    return PROTECTED_GROUPS.includes(name);
  }
}
export const groups = new GroupManager();
